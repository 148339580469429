import React, { lazy, Suspense } from 'react';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import { AppLayout } from '../layout/Layout';
import { NotFound } from '../pages/NotFound';
import { Roles } from './Protected';
import { Protected, RolePolicy } from './Protected';
import { Spin } from 'antd';
import { EmployeeFinancialEntityScreen } from "../pages/employee/financialEntities/FinancialEntityScreen.jsx";

const ReportNewApplicantScreen = lazy(() =>
  import('../pages/applicantProcess/reports/ReportNewApplicantScreen'),
);
const ReportApplicationScreen = lazy(() =>
  import('../pages/applicantProcess/reports/ReportApplicationScreen'),
);
const HomeScreen = lazy(() => import('../pages/home/HomeScreen'));
const ProjectScreen = lazy(() => import('../pages/project/ProjectScreen'));
const BillingForecastScreen = lazy(() =>
  import('../pages/forecast/billingForecast/BillingForecastScreen'),
);
const PayrollScreen = lazy(() => import('../pages/payroll/PayrollScreen'));
const CurrencyScreen = lazy(() => import('../pages/currency/CurrencyScreen'));
const EmployeeScreen = lazy(() => import('../pages/employee/EmployeeScreen'));
const ContractScreen = lazy(() => import('../pages/employee/ContractScreen'));
const TimesheetScreen = lazy(() =>
  import('../pages/employee/timesheet/TimesheetScreen'),
);
const TimesheetLinesScreen = lazy(() =>
  import('../pages/employee/timesheet/[id]/TimesheetLinesScreen'),
);
const TimesheetReportScreen = lazy(() =>
  import('../pages/employee/timesheet/TimesheetReportScreen'),
);
const TimesheetTaskScreen = lazy(() =>
  import('../pages/employee/timesheet/TimesheetTaskScreen'),
);
const AssignmentScreen = lazy(() =>
  import('../pages/assignment/AssignmentScreen'),
);
const TreeDonationScreen = lazy(() =>
  import('../pages/treeDonation/TreeDonationScreen'),
);
const HourReportScreen = lazy(() =>
  import('../pages/hourReport/HourReportScreen'),
);
const SalaryConceptScreen = lazy(() =>
  import('../pages/settings/salaryConcept/SalaryConceptScreen'),
);
const SalaryConceptReportScreen = lazy(() =>
  import('../pages/settings/salaryConcept/SalaryConceptReportScreen'),
);
const ProcessStagesScreen = lazy(() =>
  import('../pages/settings/applicantStages/ProcessStagesScreen'),
);
const TagMatchersScreen = lazy(() =>
  import('../pages/settings/tagMatchers/TagMatchersScreen'),
);
const JobsScreen = lazy(() => import('../pages/settings/jobs/JobsScreen'));
const DepartureCategoryScreen = lazy(() => import('../pages/settings/departureCategory/DepartureCategoryScreen.jsx'));
//const TechStackScreen = lazy(() => import('../pages/settings/techStack/TechStackScreen'));
const ApplicantScreen = lazy(() =>
  import('../pages/applicants/ApplicantScreen'),
);
const SettlementByRecruiterScreen = lazy(() =>
  import('../pages/applicants/SettlementsByRecruiterScreen'),
);
const SearchCandidateScreen = lazy(() =>
  import('../pages/applicants/SearchCandidateScreen'),
);
const RecruitmentGoalsScreen = lazy(() =>
  import('../pages/settings/recruitmentGoals/RecruitmentGoalsScreen'),
);
const BillingRouter = lazy(() => import('../pages/billing/BillingRouter'));
const FinancialForecastScreen = lazy(() =>
  import('../pages/forecast/financialForecast/FinancialForecastScreen'),
);
//const GoalsByRecruiterScreen = lazy(() => import('../pages/applicants/GoalsByRecruiterScreen'));
const ClientContractsByManagerScreen = lazy(() =>
  import('../pages/projectReports/ClientContractsByManagerScreen.js'),
);
const DashboardScreen = lazy(() =>
  import('../pages/dashboard/DashboardScreen'),
);
const JobProposalScreen = lazy(() =>
  import('../pages/jobProposal/JobProposalScreen.jsx'),
);
const BenefitTypeScreen = lazy(() =>
  import('../pages/settings/benefit/BenefitTypeScreen.jsx'),
);
const JobProposalSettingScreen = lazy(() =>
  import('../pages/settings/jobProposal/JobProposalSettingScreen.jsx'),
);
const JobPositionScreen = lazy(() =>
  import('../pages/jobPosition/JobPositionScreen.jsx'),
);
const PublishedJobPositionScreen = lazy(() =>
  import('../pages/jobPosition/PublishedJobPositionScreen.jsx'),
);
const CustomerScreen = lazy(() =>
  import('../pages/customer/CustomerScreen.jsx'),
);
const DepartmentScreen = lazy(() =>
  import('../pages/department/DepartmentScreen.jsx'),
);
const TagScreen = lazy(() => import('../pages/settings/tag/TagScreen.jsx'));
const HRCommissionDashboardScreen = lazy(() =>
  import('../pages/applicants/HRCommissionDashboardScreen.jsx'),
);
const MyTasksScreen = lazy(() =>
  import('../pages/myTasks/MyTasksScreen'),
);
const OnboardingScreen = lazy(() =>
  import('../pages/onboarding/OnboardingScreen.jsx'),
);
const OffboardingScreen = lazy(() =>
  import('../pages/offboarding/OffboardingScreen.jsx'),
);
const ProjectOnboardingScreen = lazy(() =>
  import('../pages/projectOnboarding/ProjectOnboardingScreen.jsx'),
);
const ProjectOffboardingScreen = lazy(() =>
  import('../pages/projectOffboarding/ProjectOffboardingScreen.jsx'),
);
const ApplicantProcessScreen = lazy(() =>
  import('../pages/applicantProcess/ApplicantProcessScreen.jsx'),
);
const ProcessRefuseCategoryScreen = lazy(() =>
  import(
    '../pages/settings/applicantProcessRefuseCategory/ProcessRefuseCategoryScreen.jsx'
  ),
);
const TaskTemplateScreen = lazy(() =>
  import('../pages/task/TaskTemplateScreen.jsx'),
);
const LanguageAssessmentReportScreen = lazy(() =>
  import(
    '../pages/employee/languageAssessment/LanguageAssessmentReportScreen.jsx'
  ),
);
const ProcessPipelineScreen = lazy(() =>
  import('../pages/applicantProcess/ProcessPipelineScreen.jsx'),
);
const HrCommissionPolicyScreen = lazy(() =>
  import('../pages/settings/hrCommissionPolicy/HrCommissionPolicyScreen.jsx'),
);
const HrCommissionScreen = lazy(() =>
  import('../pages/hrCommission/HrCommissionScreen.jsx'),
);
const ContactScreen = lazy(() =>
  import('../pages/settings/contacts/ContactScreen.jsx'),
);
const ContactRoleScreen = lazy(() =>
  import('../pages/settings/contacts/ContactRoleScreen.jsx'),
);
const FeedbackTemplateScreen = lazy(() =>
  import('../pages/settings/feedbackTemplate/FeedbackTemplateScreen'),
);
const ActivityDashboardScreen = lazy(() =>
  import('../pages/activityDashboard/ActivityDashboardScreen'),
);
const CalendarScreen = lazy(() => import('../pages/calendar/CalendarScreen'));
const ChatGptResumeScreen = lazy(() => import('../pages/utils/chatGpt/ChatGptResumeScreen'))
const PaymentMethodTypeScreen = lazy(() => import('../pages/employee/paymentMethodTypes/PaymentMethodTypeScreen.jsx'));
const RecruitmentDashboardScreen = lazy(() => import('../pages/recruitmentDashboard/RecruitmentDashboardScreen.jsx'));
const SalesCommissionScreen = lazy(() => import('../pages/salesCommission/SalesCommissionScreen.jsx'))
const SalesCommissionPolicyScreen = lazy(() => import('../pages/salesCommission/SalesCommissionPolicyScreen.jsx'))


const routesArray = [
  {
    path: '/',
    element: <HomeScreen />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
  {
    path: 'dashboard',
    element: (
      <Protected
        route
        roles={[Roles.SUPERADMIN, Roles.SALE]}
        policy={RolePolicy.MATCH_ANY}
        children={<DashboardScreen />}
      />
    ),
  },
  {
    path: 'activity-dashboard',
    element: (
      <Protected
        route
        roles={[Roles.SUPERADMIN]}
        children={<ActivityDashboardScreen />}
      />
    ),
  },
  {
    path: 'contracts/*',
    element: (
      <Protected
        route
        roles={[Roles.SUPERADMIN, Roles.SALE]}
        policy={RolePolicy.MATCH_ANY}
        children={<ProjectScreen />}
      />
    ),
  },
  {
    path: 'customers/*',
    element: (
      <Protected
        route
        roles={[Roles.SUPERADMIN, Roles.RECRUITER, Roles.SALE]}
        policy={RolePolicy.MATCH_ANY}
        children={<CustomerScreen />}
      />
    ),
  },
  {
    path: 'billing/*',
    element: (
      <Protected
        route
        roles={[Roles.SUPERADMIN, Roles.SALE]}
        policy={RolePolicy.MATCH_ANY}
        children={<BillingRouter />}
      />
    ),
  },
  {
    path: 'currencies',
    element: (
      <Protected
        route
        roles={[Roles.SUPERADMIN]}
        children={<CurrencyScreen />}
      />
    ),
  },
  {
    path: 'employees/*',
    element: (
      <Protected
        route
        roles={[
          Roles.SUPERADMIN,
          Roles.RECRUITER,
          Roles.DATA_ENTRY,
          Roles.HR,
          Roles.ADMINISTRATIVE,
        ]}
        policy={RolePolicy.MATCH_ANY}
        children={<EmployeeScreen />}
      />
    ),
  },
  {
    path: 'employee-contracts',
    element: (
      <Protected
        route
        roles={[Roles.SUPERADMIN]}
        children={<ContractScreen />}
      />
    ),
  },
  {
    path: 'timesheets',
    children: [
      {
        index: true,
        path: '*',
        element: (
          <Protected
            route
            roles={[Roles.SUPERADMIN, Roles.ADMINISTRATIVE]}
            policy={RolePolicy.MATCH_ANY}
            children={<TimesheetScreen />}
          />
        ),
      },
      {
        path: ':id/*',
        element: (
          <Protected
            route
            roles={[Roles.SUPERADMIN, Roles.ADMINISTRATIVE]}
            policy={RolePolicy.MATCH_ANY}
            children={<TimesheetLinesScreen />}
          />
        ),
      },
      {
        path: 'tasks',
        element: (
          <Protected
            route
            roles={[Roles.SUPERADMIN, Roles.ADMINISTRATIVE]}
            policy={RolePolicy.MATCH_ANY}
            children={<TimesheetTaskScreen />}
          />
        ),
      },
    ],
  },
  {
    path: 'payroll/*',
    element: (
      <Protected
        route
        roles={[Roles.SUPERADMIN]}
        children={<PayrollScreen />}
      />
    ),
  },
  {
    path: 'settings',
    children: [
      {
        path: 'salary-concepts',
        element: (
          <Protected
            route
            roles={[Roles.SUPERADMIN]}
            children={<SalaryConceptScreen />}
          />
        ),
      },
      {
        path: 'contacts',
        element: (
          <Protected
            route
            roles={[Roles.SUPERADMIN]}
            children={<ContactScreen />}
          />
        ),
      },
      {
        path: 'contact-roles',
        element: (
          <Protected
            route
            roles={[Roles.SUPERADMIN]}
            children={<ContactRoleScreen />}
          />
        ),
      },
      {
        path: 'applicant-stages',
        element: (
          <Protected
            route
            roles={[Roles.SUPERADMIN]}
            children={<ProcessStagesScreen />}
          />
        ),
      },
      {
        path: 'commission-policies',
        element: (
          <Protected
            route
            roles={[Roles.SUPERADMIN]}
            children={<HrCommissionPolicyScreen />}
          />
        ),
      },
      {
        path: 'applicant-refuse-category',
        element: (
          <Protected
            route
            roles={[Roles.SUPERADMIN, Roles.RECRUITER]}
            policy={RolePolicy.MATCH_ANY}
            children={<ProcessRefuseCategoryScreen />}
          />
        ),
      },
      // {
      //   path: "tech-stack",
      //   element: (
      //     <Protected
      //       route
      //       roles={[Roles.SUPERADMIN, Roles.RECRUITER]}
      //       policy={RolePolicy.MATCH_ANY}
      //       children={<TechStackScreen />}
      //     />
      //   ),
      // },
      {
        path: 'tag-matchers',
        element: (
          <Protected
            route
            roles={[Roles.SUPERADMIN, Roles.RECRUITER]}
            policy={RolePolicy.MATCH_ANY}
            children={<TagMatchersScreen />}
          />
        ),
      },
      {
        path: 'jobs',
        element: (
          <Protected
            route
            roles={[Roles.SUPERADMIN, Roles.RECRUITER]}
            policy={RolePolicy.MATCH_ANY}
            children={<JobsScreen />}
          />
        ),
      },
      {
        path: 'feedback-templates',
        element: (
          <Protected
            route
            roles={[Roles.SUPERADMIN, Roles.RECRUITER, Roles.ADMINISTRATIVE, Roles.HR]}
            policy={RolePolicy.MATCH_ANY}
            children={<FeedbackTemplateScreen />}
          />
        ),
      },
      {
        path: 'benefit-types',
        element: (
          <Protected
            route
            roles={[Roles.SUPERADMIN]}
            children={<BenefitTypeScreen />}
          />
        ),
      },
      {
        path: 'recruitment-goals',
        element: (
          <Protected
            route
            roles={[Roles.SUPERADMIN]}
            children={<RecruitmentGoalsScreen />}
          />
        ),
      },
      {
        path: 'job-proposal',
        element: (
          <Protected
            route
            roles={[Roles.SUPERADMIN]}
            children={<JobProposalSettingScreen />}
          />
        ),
      },
      {
        path: 'tag',
        element: (
          <Protected
            route
            roles={[Roles.SUPERADMIN]}
            children={<TagScreen />}
          />
        ),
      },
      {
        path: "payment-method",
        children: [
          {
            path: "types/*",
            element: (
              <Protected
                route
                roles={[Roles.SUPERADMIN]}
                children={<PaymentMethodTypeScreen />}
              />
            ),
          },
          {
            path: "financial-entities",
            element: (
              <Protected
                route
                roles={[Roles.SUPERADMIN]}
                children={<EmployeeFinancialEntityScreen />}
              />
            ),
          }
        ],
      },
      {
        path: "departure-categories/*",
        element: (
          <Protected
            route
            roles={[Roles.SUPERADMIN]}
            children={<DepartureCategoryScreen />}
          />
        ),
      },
    ],
  },
  {
    path: 'utils',
    children: [
      {
        path: 'chatgpt-resume',
        element: (
          <Protected
            route
            roles={[
              Roles.SUPERADMIN,
              Roles.ADMINISTRATIVE,
              Roles.RECRUITER,
              Roles.RECRUITER_EXTERNAL,
            ]}
            policy={RolePolicy.MATCH_ANY}
            children={<ChatGptResumeScreen />}
          />
        ),
      },
    ],
  },
  {
    path: 'utils',
    children: [
      {
        path: 'chatgpt-resume',
        element: (
          <Protected
            route
            roles={[
              Roles.SUPERADMIN,
              Roles.ADMINISTRATIVE,
              Roles.RECRUITER,
              Roles.RECRUITER_EXTERNAL,
            ]}
            policy={RolePolicy.MATCH_ANY}
            children={<ChatGptResumeScreen />}
          />
        ),
      },
    ],
  },
  {
    path: 'recruitment/dashboard',
    element: (
      <Protected
        route
        roles={[Roles.SUPERADMIN]}
        children={<RecruitmentDashboardScreen />}
      />
    ),
  },
  {
    path: 'recruitment/commission/dashboard',
    element: (
      <Protected
        route
        roles={[Roles.SUPERADMIN, Roles.RECRUITER]}
        policy={RolePolicy.MATCH_ANY}
        children={<HRCommissionDashboardScreen />}
      />
    ),
  },
  {
    path: 'tasks/me',
    element: (
      <Protected
        route
        roles={[Roles.SUPERADMIN, Roles.HR, Roles.ADMINISTRATIVE]}
        policy={RolePolicy.MATCH_ANY}
        children={<MyTasksScreen />}
      />
    ),
  },
  {
    path: 'onboardings/*',
    element: (
      <Protected
        route
        roles={[Roles.SUPERADMIN, Roles.HR, Roles.ADMINISTRATIVE]}
        policy={RolePolicy.MATCH_ANY}
        children={<OnboardingScreen />}
      />
    ),
  },
  {
    path: 'offboardings/*',
    element: (
      <Protected
        route
        roles={[Roles.SUPERADMIN, Roles.HR, Roles.ADMINISTRATIVE]}
        policy={RolePolicy.MATCH_ANY}
        children={<OffboardingScreen />}
      />
    ),
  },
  {
    path: 'project-onboardings/*',
    element: (
      <Protected
        route
        roles={[Roles.SUPERADMIN, Roles.HR, Roles.ADMINISTRATIVE]}
        policy={RolePolicy.MATCH_ANY}
        children={<ProjectOnboardingScreen />}
      />
    ),
  },
  {
    path: 'project-offboardings/*',
    element: (
      <Protected
        route
        roles={[Roles.SUPERADMIN, Roles.HR, Roles.ADMINISTRATIVE]}
        policy={RolePolicy.MATCH_ANY}
        children={<ProjectOffboardingScreen />}
      />
    ),
  },
  {
    path: 'applicant-process/*',
    element: (
      <Protected
        route
        roles={[Roles.SUPERADMIN, Roles.RECRUITER]}
        policy={RolePolicy.MATCH_ANY}
        children={<ApplicantProcessScreen />}
      />
    ),
  },
  {
    path: 'pipeline-process/*',
    element: (
      <Protected
        route
        roles={[Roles.SUPERADMIN, Roles.RECRUITER]}
        policy={RolePolicy.MATCH_ANY}
        children={<ProcessPipelineScreen />}
      />
    ),
  },
  {
    path: 'task-templates/*',
    element: (
      <Protected
        route
        roles={[Roles.SUPERADMIN, Roles.ADMINISTRATIVE]}
        policy={RolePolicy.MATCH_ANY}
        children={<TaskTemplateScreen />}
      />
    ),
  },
  {
    path: 'commissions/*',
    element: (
      <Protected
        route
        roles={[Roles.SUPERADMIN]}
        children={<HrCommissionScreen />}
      />
    ),
  },
  {
    path: 'applicants',
    children: [
      {
        index: true,
        path: '*',
        element: (
          <Protected
            route
            roles={[Roles.SUPERADMIN, Roles.RECRUITER]}
            policy={RolePolicy.MATCH_ANY}
            children={<ApplicantScreen />}
          />
        ),
      },
      {
        path: 'search',
        element: (
          <Protected
            route
            roles={[Roles.SUPERADMIN, Roles.RECRUITER]}
            policy={RolePolicy.MATCH_ANY}
            children={<SearchCandidateScreen />}
          />
        ),
      },
      {
        path: 'settlements-by-recruiter',
        element: (
          <Protected
            route
            roles={[Roles.SUPERADMIN]}
            children={<SettlementByRecruiterScreen />}
          />
        ),
      },
    ],
  },
  {
    path: 'job-proposals/*',
    element: (
      <Protected
        route
        roles={[Roles.SUPERADMIN, Roles.RECRUITER]}
        policy={RolePolicy.MATCH_ANY}
        children={<JobProposalScreen />}
      />
    ),
  },
  {
    path: 'job-positions/*',
    element: (
      <Protected
        route
        roles={[Roles.SUPERADMIN, Roles.RECRUITER, Roles.RECRUITER_EXTERNAL]}
        policy={RolePolicy.MATCH_ANY}
        children={<JobPositionScreen />}
      />
    ),
  },
  {
    path: 'job-positions/published/*',
    element: (
      <Protected
        route
        roles={[Roles.SUPERADMIN, Roles.RECRUITER]}
        policy={RolePolicy.MATCH_ANY}
        children={<PublishedJobPositionScreen />}
      />
    ),
  },
  {
    path: 'job-positions/published/*',
    element: (
      <Protected
        route
        roles={[Roles.SUPERADMIN, Roles.RECRUITER]}
        policy={RolePolicy.MATCH_ANY}
        children={<PublishedJobPositionScreen />}
      />
    ),
  },
  {
    path: 'departments/*',
    element: (
      <Protected
        route
        roles={[Roles.SUPERADMIN, Roles.RECRUITER]}
        policy={RolePolicy.MATCH_ANY}
        children={<DepartmentScreen />}
      />
    ),
  },
  {
    path: 'report',
    children: [
      {
        path: 'hour',
        element: (
          <Protected
            route
            roles={[Roles.SUPERADMIN]}
            children={<HourReportScreen />}
          />
        ),
      },
      {
        path: 'assignments',
        element: (
          <Protected
            route
            roles={[Roles.SUPERADMIN]}
            children={<AssignmentScreen />}
          />
        ),
      },
      {
        path: 'employee-assessments/*',
        element: (
          <Protected
            route
            roles={[Roles.SUPERADMIN, Roles.HR]}
            policy={RolePolicy.MATCH_ANY}
            children={<LanguageAssessmentReportScreen />}
          />
        ),
      },
      {
        path: 'salary-concepts',
        element: (
          <Protected
            route
            roles={[Roles.SUPERADMIN]}
            children={<SalaryConceptReportScreen />}
          />
        ),
      },
      {
        path: 'client-contracts/*',
        element: (
          <Protected
            route
            roles={[Roles.SUPERADMIN, Roles.HR, Roles.SALE]}
            policy={RolePolicy.MATCH_ANY}
            children={<ClientContractsByManagerScreen />}
          />
        ),
      },
      {
        path: 'forecast',
        children: [
          {
            path: 'billing',
            element: (
              <Protected
                route
                roles={[Roles.SUPERADMIN, Roles.SALE]}
                policy={RolePolicy.MATCH_ANY}
                children={<BillingForecastScreen />}
              />
            ),
          },
          {
            path: 'financial',
            element: (
              <Protected
                route
                roles={[Roles.SUPERADMIN]}
                children={<FinancialForecastScreen />}
              />
            ),
          },
        ],
      },
      {
        path: 'recruitment',
        children: [
          {
            path: 'applicants',
            element: (
              <Protected
                route
                roles={[Roles.SUPERADMIN, Roles.RECRUITER]}
                policy={RolePolicy.MATCH_ANY}
                children={<ReportNewApplicantScreen />}
              />
            ),
          },
          {
            path: 'applications',
            element: (
              <Protected
                route
                roles={[Roles.SUPERADMIN, Roles.RECRUITER]}
                policy={RolePolicy.MATCH_ANY}
                children={<ReportApplicationScreen />}
              />
            ),
          },
        ],
      },
      {
        path: 'forecast',
        children: [
          {
            path: 'billing',
            element: (
              <Protected
                route
                roles={[Roles.SUPERADMIN]}
                children={<BillingForecastScreen />}
              />
            ),
          },
          {
            path: 'financial',
            element: (
              <Protected
                route
                roles={[Roles.SUPERADMIN]}
                children={<FinancialForecastScreen />}
              />
            ),
          },
        ],
      },
      {
        path: 'timesheets',
        element: (
          <Protected
            route
            roles={[Roles.SUPERADMIN]}
            children={<TimesheetReportScreen />}
          />
        ),
      },
      // {
      //   path: 'recruitment',
      //   children: [
      //     {
      //       path: 'goals',
      //       element:
      //           <Protected
      //             route
      //             roles={[Roles.SUPERADMIN, Roles.RECRUITER]}
      //             policy={RolePolicy.MATCH_ANY}
      //             children={<GoalsByRecruiterScreen />}
      //           />,
      //     }
      //   ]
      // },
    ],
  },
  {
    path: 'calendar/*',
    element: (
      <Protected
        route
        roles={[Roles.SUPERADMIN, Roles.ADMINISTRATIVE]}
        policy={RolePolicy.MATCH_ANY}
        children={<CalendarScreen />}
      />
    ),
  },
  {
    path: 'tree-donation',
    element: (
      <Protected
        route
        roles={[Roles.SUPERADMIN, Roles.ADMINISTRATIVE, Roles.HR]}
        policy={RolePolicy.MATCH_ANY}
        children={<TreeDonationScreen />}
      />
    ),
  },
  {
    path: 'sales-commission',
    children: [
      {
        path: 'commissions',
        element:
          <Protected
            route
            roles={[Roles.SUPERADMIN, Roles.ADMINISTRATIVE]}
            policy={RolePolicy.MATCH_ANY}
            children={<SalesCommissionScreen />}
          />
      },
      {
        path: 'policies',
        element:
          <Protected
            route
            roles={[Roles.SUPERADMIN, Roles.ADMINISTRATIVE]}
            policy={RolePolicy.MATCH_ANY}
            children={<SalesCommissionPolicyScreen />}
          />
      }
    ]
  }
];

const AppRoutes = () => {
  return useRoutes(routesArray);
};

export const Router = () => (
  <BrowserRouter basename={`${process.env.REACT_APP_PUBLIC_URL}`}>
    <AppLayout>
      <Suspense fallback={<Spin spinning />}>
        <AppRoutes />
      </Suspense>
    </AppLayout>
  </BrowserRouter>
);
